// * LORA
@font-face {
    font-family: "Lora";
    font-weight: 500;
    src: url("../fonts/Lora/Lora-Regular.ttf") format("truetype");
    // src: url("../fonts/Lora/Lora-Regular.ttf") format("ttf");
    src: url("../fonts/Lora/Lora-Regular.woff") format("woff");
    src: url("../fonts/Lora/Lora-Regular.woff2") format("woff2");
}

@font-face {
    font-family: "Lora";
    font-weight: bold;
    src: url("../fonts/Lora/Lora-Bold.ttf") format("truetype");
    src: url("../fonts/Lora/Lora-Bold.woff") format("woff");
    src: url("../fonts/Lora/Lora-Bold.woff2") format("woff2");
}

// * TITILLIUM WEB
@font-face {
    font-family: "TitilliumWeb";
    font-weight: 900;
    src: url("../fonts/Titillium_Web/TitilliumWeb-Black.ttf") format("ttf");
    src: url("../fonts/Titillium_Web/TitilliumWeb-Black.woff") format("woff");
    src: url("../fonts/Titillium_Web/TitilliumWeb-Black.woff2") format("woff2");
}

@font-face {
    font-family: "TitilliumWeb";
    font-weight: bold;
    src: url("../fonts/Titillium_Web/TitilliumWeb-Bold.ttf") format("ttf");
    src: url("../fonts/Titillium_Web/TitilliumWeb-Bold.woff") format("woff");
    src: url("../fonts/Titillium_Web/TitilliumWeb-Bold.woff2") format("woff2");
}

@font-face {
    font-family: "TitilliumWeb";
    font-weight: 300;
    src: url("../fonts/Titillium_Web/TitilliumWeb-ExtraLight.ttf") format("ttf");
    src: url("../fonts/Titillium_Web/TitilliumWeb-ExtraLight.woff") format("woff");
    src: url("../fonts/Titillium_Web/TitilliumWeb-ExtraLight.woff2") format("woff2");
}

@font-face {
    font-family: "TitilliumWeb";
    font-weight: lighter;
    src: url("../fonts/Titillium_Web/TitilliumWeb-Light.ttf") format("ttf");
    src: url("../fonts/Titillium_Web/TitilliumWeb-Light.woff") format("woff");
    src: url("../fonts/Titillium_Web/TitilliumWeb-Light.woff2") format("woff2");
}


@font-face {
    font-family: "TitilliumWeb";
    font-weight: 500;
    src: url("../fonts/Titillium_Web/TitilliumWeb-Regular.ttf") format("ttf");
    src: url("../fonts/Titillium_Web/TitilliumWeb-Regular.woff") format("woff");
    src: url("../fonts/Titillium_Web/TitilliumWeb-Regular.woff2") format("woff2");
}

@font-face {
    font-family: "TitilliumWeb";
    font-weight: 600;
    src: url("../fonts/Titillium_Web/TitilliumWeb-SemiBold.ttf") format("ttf");
    src: url("../fonts/Titillium_Web/TitilliumWeb-SemiBold.woff") format("woff");
    src: url("../fonts/Titillium_Web/TitilliumWeb-SemiBold.woff2") format("woff2");
}