/* You can add global styles to this file, and also import other style files */
@import "./assets/styles/fonts.scss";
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";


html {
  overflow-x: hidden;
  width: 100vw;
  scroll-behavior: smooth;
}

html,
body {

  font-family: "TitilliumWeb",
    sans-serif;

  .it-header-slim-wrapper.theme-light .it-header-slim-wrapper-content .header-slim-right-zone .dropdown-menu:before,
  .it-header-slim-wrapper.theme-light .it-header-slim-wrapper-content .it-header-slim-right-zone .dropdown-menu:before {
    background: none !important;
  }

  .readable-font,
  .readable-font *:not(i):not(span) {
    font-family: Verdana, Arial, Helvetica, sans-serif !important;
  }

  body.light-background-accessibility,
  body.light-background-accessibility *:not(#pojo-a11y-toolbar):not(.pojo-a11y-toolbar-link) {
    background: var(--white-color) !important;
    color: var(--black-color) !important;
  }

  body.light-background-accessibility div#gmap,
  body.light-background-accessibility div#gmap * {
    background: initial !important;
  }

  .links-underline {

    a,
    u {
      text-decoration: underline !important;
    }
  }

  .negative-contrast {
    filter: invert(1);
    background: var(--black-color);
  }

  .f-12 {
    font-size: 12px;
  }

  .f-13 {
    font-size: 13px;
  }

  .f-14 {
    font-size: 14px;
  }

  .f-15 {
    font-size: 15px;
  }

  .f-16 {
    font-size: 16px;
  }

  .f-17 {
    font-size: 17px;
  }

  .f-18 {
    font-size: 18px;
  }

  .grayscale {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    -webkit-filter: grayscale(1);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: var(--gray-color);
    ;
  }



  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--shadow-box);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--label-disabled);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--gray-color);
  }
}


// HEADER DROPDOWNS
.it-header-navbar-wrapper.theme-light-desk .navbar .navbar-collapsable .navbar-nav li.nav-item.dropdown a.nav-link.white:before,
.it-header-navbar-wrapper.theme-light-desk .navbar .dropdown-menu.white:before {
  background: none !important;
  background-color: var(--white-color) !important;
}

svg-icon-sprite.icon-danger {
  color: var(--alert-color) !important;
}

svg-icon-sprite.expand-icon>svg {
  width: 1.33em !important;
  height: 1.33em !important;
}

.read-more {
  text-decoration: underline !important;
  cursor: pointer;
}

.icon-expand {
  filter: drop-shadow(0.1875em 0.3125em 2px var(--drop-shadow));
}

body.high-contrast-accessibility {
  .dropdown-menu {
    background-color: var(--black-color) !important;
  }

  .dropdown-toggle::before {
    background-color: var(--black-color) !important;
  }
}

.dropdown-menu.white {
  background: var(--white-color) !important;

  .link-list-wrapper ul.link-list li h3 {
    color: var(--dark-blue-color) !important;
  }

  .link-list-wrapper ul li a span {
    padding: 0;
    color: var(--primary-color) !important;
    cursor: pointer;
  }
}

.modal.it-dialog-scrollable .modal-dialog .modal-content {
  height: fit-content !important;
  max-height: 80vh;
  overflow: auto;
}

.see-all-btn-container {
  margin-bottom: 1.25em;
}

.autocomplete-icon {
  background-color: transparent !important;
  padding: 0 !important;
  right: -2em !important;
}

.no_result_wrapper {
  text-align: center;
  margin: 2.5em;
}

// * TABLE CSS BO
.table-container {
  width: 80%;
  padding-bottom: 3em;
  margin: auto;
}

// * END TABLE CSS BO

app-risk-management {
  .content-container {
    margin-left: 8% !important;
    margin-right: auto !important;
    width: 50% !important;
    margin-bottom: 40px !important;

    h2 {
      strong {
        display: none !important;
      }
    }

    table {
      width: 100%;

      tr {
        border-bottom: 0.03125em solid var(--gray-color) !important;
      }

      tbody {
        td {
          padding: 0.9375em 0.625em !important;
        }

        td:first-of-type {
          color: var(--dark-blue-color) !important;
        }

        td:last-of-type {
          color: var(--gray-color) !important;
        }
      }
    }
  }
}

.required-marker::after {
  content: "*";
}

@media (max-width: 1300px) {

  svg-icon-sprite.expand-icon>svg {
    height: 32px !important;
  }
}

@media screen and (max-width: 1200px) {

  app-risk-management {
    .content-container {
      width: 100% !important;
    }
  }

  app-notices-detail-body {
    .it-timeline-wrapper .timeline-element {
      padding: 1.25em !important;
      margin: 10% 0;
    }
  }

  app-avvisi-detail-body {
    .it-timeline-wrapper .timeline-element {
      padding: 1.25em !important;
      margin: 10% 0;
    }
  }
}

@media screen and (max-width: 550px) {
  html {
    overflow-x: hidden
  }

  app-risk-management {
    .content-container {
      width: 100% !important;
    }
  }
}

app-news-detail-body {
  .content {
    width: 100% !important;

    img {
      object-fit: contain !important;
    }
  }
}

@media (max-width: 550px) {
  .modal.it-dialog-scrollable .modal-dialog .modal-content {
    height: fit-content !important;
    max-height: 80vh;
    overflow: auto;
    font-size: smaller;
  }

  app-risk-management {
    .body .title {
      width: 80% !important;
    }

    .content-container {
      width: 80% !important;
    }
  }

  app-news-detail-body {
    .content {
      img {
        width: 100% !important;
      }
    }
  }
}

@media (max-width: 1200px) {
  .modal.it-dialog-scrollable .modal-dialog .modal-content {
    height: fit-content !important;
    max-height: 80vh;
    overflow: auto;
    font-size: small;
  }
}

app-news,
app-pages-documental,
app-department-documental {
  .create-button {
    position: fixed;
    border-radius: 12em;
    bottom: 1em;
    right: 1em;
    white-space: nowrap;
    overflow: hidden;
    z-index: 100;
  }
}

app-user-management {
  .link-list-wrapper ul .form-check.form-check-group [type=checkbox]+label:after {
    right: 0 !important;
  }
}



app-news {
  .dropdown-menu.show {
    transform: translate3d(-50%, 28px, 0em) !important;
  }
}

app-editor {
  .ql-disabled>div.ql-editor {
    background-color: var(--disabled-color) !important;
    cursor: not-allowed;
  }

  .permalink-modal {
    .modal-dialog {
      margin: 64px 84px !important;

      .modal-body {
        padding-bottom: 84px !important;
      }
    }
  }
}


app-privacy {
  #inner-data {
    ul {
      //list-style-image: url("../../../assets/sprite.svg#it-file");
      display: flex;
      flex-wrap: wrap;
      row-gap: 28px;
      column-gap: 1em;
      margin-bottom: 3.125em;
      justify-content: center;
      padding: 0;

      li {
        background-color: var(--white-color);
        //@at-root: url("/assets/file.svg");
        background-position-x: 1.25em;
        background-position-y: 1.25em;
        background-repeat: no-repeat;
        box-shadow: 0 0 80px 0 var(--shadow-box);
        height: 100%;
        display: flex;
        flex-direction: row;
        min-height: 280px;
        min-width: 280px;

        a {
          color: var(--light-blue-color);
          font-size: 1.25em;
          line-height: 28px;
          text-align: left;
          font-weight: bold;
          text-decoration: underline;
          width: 300px;
          letter-spacing: 2px;
          padding: 5%;
        }

        @media screen and (max-width: 1050px) {
          a {
            color: var(--light-blue-color);
            line-height: 28px;
            text-align: left;
            font-weight: bold;
            text-decoration: underline;
            margin-bottom: 0.625em;
            margin-right: 5%;
            width: 300px;
            letter-spacing: 2px;
            padding: 5%;
            font-size: medium !important;
          }
        }

        @media screen and (max-width: 550px) {
          a {
            color: var(--light-blue-color);
            line-height: 28px;
            text-align: left;
            font-weight: bold;
            width: 300px;
            text-decoration: underline;
            letter-spacing: 2px;
            padding: 5%;
            font-size: medium !important;
          }
        }
      }
    }
  }
}

.qlbt-operation-menu .qlbt-operation-menu-item .qlbt-operation-menu-icon {
  margin-right: 1.875em !important;
  margin-bottom: 7px !important;
}

.qlbt-operation-menu {
  z-index: 99999999;
  top: 20vh !important;
}


/** START HIGH CONTRAST **/
body.high-contrast-accessibility {
  background-color: var(--black-color);

  * {
    color: var(--white-color);
  }

  /* This stylesheet based on 0WonB.css generated by Accessibility CSS Generator, (c) Silas S. Brown 2006-2015.  Version 0.9844 */
}

body.high-contrast-accessibility .placebo {
  line-height: normal;
}

body.high-contrast-accessibility * {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

body.high-contrast-accessibility a,
body.high-contrast-accessibility abbr,
body.high-contrast-accessibility acronym,
body.high-contrast-accessibility address,
body.high-contrast-accessibility article,
body.high-contrast-accessibility aside,
body.high-contrast-accessibility b,
body.high-contrast-accessibility basefont,
body.high-contrast-accessibility bdi,
body.high-contrast-accessibility big,
body.high-contrast-accessibility blink,
body.high-contrast-accessibility blockquote,
body.high-contrast-accessibility body,
body.high-contrast-accessibility button,
body.high-contrast-accessibility canvas,
body.high-contrast-accessibility caption,
body.high-contrast-accessibility center,
body.high-contrast-accessibility cite,
body.high-contrast-accessibility code,
body.high-contrast-accessibility col,
body.high-contrast-accessibility colgroup,
body.high-contrast-accessibility command,
body.high-contrast-accessibility dd,
body.high-contrast-accessibility del,
body.high-contrast-accessibility details,
body.high-contrast-accessibility dfn,
body.high-contrast-accessibility dir,
body.high-contrast-accessibility div,
body.high-contrast-accessibility dl,
body.high-contrast-accessibility dt,
body.high-contrast-accessibility em,
body.high-contrast-accessibility embed,
body.high-contrast-accessibility fieldset,
body.high-contrast-accessibility figcaption,
body.high-contrast-accessibility figure,
body.high-contrast-accessibility font,
body.high-contrast-accessibility footer,
body.high-contrast-accessibility form,
body.high-contrast-accessibility h1,
body.high-contrast-accessibility h1 a,
body.high-contrast-accessibility h1 a b,
body.high-contrast-accessibility h1 abbr,
body.high-contrast-accessibility h1 b,
body.high-contrast-accessibility h1 center,
body.high-contrast-accessibility h1 em,
body.high-contrast-accessibility h1 i,
body.high-contrast-accessibility h1 span,
body.high-contrast-accessibility h1 strong,
body.high-contrast-accessibility h2,
body.high-contrast-accessibility h2 a,
body.high-contrast-accessibility h2 a b,
body.high-contrast-accessibility h2 abbr,
body.high-contrast-accessibility h2 b,
body.high-contrast-accessibility h2 center,
body.high-contrast-accessibility h2 em,
body.high-contrast-accessibility h2 i,
body.high-contrast-accessibility h2 span,
body.high-contrast-accessibility h2 strong,
body.high-contrast-accessibility h3,
body.high-contrast-accessibility h3 a,
body.high-contrast-accessibility h3 a b,
body.high-contrast-accessibility h3 abbr,
body.high-contrast-accessibility h3 b,
body.high-contrast-accessibility h3 center,
body.high-contrast-accessibility h3 em,
body.high-contrast-accessibility h3 i,
body.high-contrast-accessibility h3 span,
body.high-contrast-accessibility h3 strong,
body.high-contrast-accessibility h4,
body.high-contrast-accessibility h4 a,
body.high-contrast-accessibility h4 a b,
body.high-contrast-accessibility h4 abbr,
body.high-contrast-accessibility h4 b,
body.high-contrast-accessibility h4 center,
body.high-contrast-accessibility h4 em,
body.high-contrast-accessibility h4 i,
body.high-contrast-accessibility h4 span,
body.high-contrast-accessibility h4 strong,
body.high-contrast-accessibility h5,
body.high-contrast-accessibility h5 a,
body.high-contrast-accessibility h5 a b,
body.high-contrast-accessibility h5 abbr,
body.high-contrast-accessibility h5 b,
body.high-contrast-accessibility h5 center,
body.high-contrast-accessibility h5 em,
body.high-contrast-accessibility h5 i,
body.high-contrast-accessibility h5 span,
body.high-contrast-accessibility h5 strong,
body.high-contrast-accessibility h6,
body.high-contrast-accessibility h6 a,
body.high-contrast-accessibility h6 a b,
body.high-contrast-accessibility h6 abbr,
body.high-contrast-accessibility h6 b,
body.high-contrast-accessibility h6 center,
body.high-contrast-accessibility h6 em,
body.high-contrast-accessibility h6 i,
body.high-contrast-accessibility h6 span,
body.high-contrast-accessibility h6 strong,
body.high-contrast-accessibility header,
body.high-contrast-accessibility hgroup,
body.high-contrast-accessibility html,
body.high-contrast-accessibility i,
body.high-contrast-accessibility iframe,
body.high-contrast-accessibility img,
body.high-contrast-accessibility input,
body.high-contrast-accessibility ins,
body.high-contrast-accessibility kbd,
body.high-contrast-accessibility label,
body.high-contrast-accessibility legend,
body.high-contrast-accessibility li,
body.high-contrast-accessibility listing,
body.high-contrast-accessibility main,
body.high-contrast-accessibility mark,
body.high-contrast-accessibility marquee,
body.high-contrast-accessibility menu,
body.high-contrast-accessibility meter,
body.high-contrast-accessibility multicol,
body.high-contrast-accessibility nav,
body.high-contrast-accessibility nobr,
body.high-contrast-accessibility object,
body.high-contrast-accessibility ol,
body.high-contrast-accessibility option,
body.high-contrast-accessibility output,
body.high-contrast-accessibility p,
body.high-contrast-accessibility plaintext,
body.high-contrast-accessibility pre,
body.high-contrast-accessibility progress,
body.high-contrast-accessibility q,
body.high-contrast-accessibility rb,
body.high-contrast-accessibility rp,
body.high-contrast-accessibility rt,
body.high-contrast-accessibility ruby,
body.high-contrast-accessibility s,
body.high-contrast-accessibility samp,
body.high-contrast-accessibility section,
body.high-contrast-accessibility select,
body.high-contrast-accessibility small,
body.high-contrast-accessibility span,
body.high-contrast-accessibility strike,
body.high-contrast-accessibility strong,
body.high-contrast-accessibility sub,
body.high-contrast-accessibility summary,
body.high-contrast-accessibility sup,
body.high-contrast-accessibility svg,
body.high-contrast-accessibility table,
body.high-contrast-accessibility tbody,
body.high-contrast-accessibility td,
body.high-contrast-accessibility text,
body.high-contrast-accessibility textarea,
body.high-contrast-accessibility th,
body.high-contrast-accessibility thead,
body.high-contrast-accessibility time,
body.high-contrast-accessibility tr,
body.high-contrast-accessibility tt,
body.high-contrast-accessibility u,
body.high-contrast-accessibility ul,
body.high-contrast-accessibility var,
body.high-contrast-accessibility video,
body.high-contrast-accessibility xmp {
  -moz-appearance: none !important;
  -moz-user-select: text !important;
  -webkit-user-select: text !important;
  background-image: none !important;
  text-shadow: none !important;
  user-select: text !important;
}

body.high-contrast-accessibility a,
body.high-contrast-accessibility abbr,
body.high-contrast-accessibility acronym,
body.high-contrast-accessibility address,
body.high-contrast-accessibility article,
body.high-contrast-accessibility aside,
body.high-contrast-accessibility b,
body.high-contrast-accessibility basefont,
body.high-contrast-accessibility bdi,
body.high-contrast-accessibility big,
body.high-contrast-accessibility blink,
body.high-contrast-accessibility blockquote,
body.high-contrast-accessibility body,
body.high-contrast-accessibility canvas,
body.high-contrast-accessibility caption,
body.high-contrast-accessibility center,
body.high-contrast-accessibility cite,
body.high-contrast-accessibility code,
body.high-contrast-accessibility col,
body.high-contrast-accessibility colgroup,
body.high-contrast-accessibility command,
body.high-contrast-accessibility dd,
body.high-contrast-accessibility del,
body.high-contrast-accessibility details,
body.high-contrast-accessibility dfn,
body.high-contrast-accessibility dir,
body.high-contrast-accessibility div,
body.high-contrast-accessibility dl,
body.high-contrast-accessibility dt,
body.high-contrast-accessibility em,
body.high-contrast-accessibility embed,
body.high-contrast-accessibility fieldset,
body.high-contrast-accessibility figcaption,
body.high-contrast-accessibility figure,
body.high-contrast-accessibility font,
body.high-contrast-accessibility footer,
body.high-contrast-accessibility form,
body.high-contrast-accessibility h1,
body.high-contrast-accessibility h1 a,
body.high-contrast-accessibility h1 a b,
body.high-contrast-accessibility h1 abbr,
body.high-contrast-accessibility h1 b,
body.high-contrast-accessibility h1 center,
body.high-contrast-accessibility h1 em,
body.high-contrast-accessibility h1 i,
body.high-contrast-accessibility h1 span,
body.high-contrast-accessibility h1 strong,
body.high-contrast-accessibility h2,
body.high-contrast-accessibility h2 a,
body.high-contrast-accessibility h2 a b,
body.high-contrast-accessibility h2 abbr,
body.high-contrast-accessibility h2 b,
body.high-contrast-accessibility h2 center,
body.high-contrast-accessibility h2 em,
body.high-contrast-accessibility h2 i,
body.high-contrast-accessibility h2 span,
body.high-contrast-accessibility h2 strong,
body.high-contrast-accessibility h3,
body.high-contrast-accessibility h3 a,
body.high-contrast-accessibility h3 a b,
body.high-contrast-accessibility h3 abbr,
body.high-contrast-accessibility h3 b,
body.high-contrast-accessibility h3 center,
body.high-contrast-accessibility h3 em,
body.high-contrast-accessibility h3 i,
body.high-contrast-accessibility h3 span,
body.high-contrast-accessibility h3 strong,
body.high-contrast-accessibility h4,
body.high-contrast-accessibility h4 a,
body.high-contrast-accessibility h4 a b,
body.high-contrast-accessibility h4 abbr,
body.high-contrast-accessibility h4 b,
body.high-contrast-accessibility h4 center,
body.high-contrast-accessibility h4 em,
body.high-contrast-accessibility h4 i,
body.high-contrast-accessibility h4 span,
body.high-contrast-accessibility h4 strong,
body.high-contrast-accessibility h5,
body.high-contrast-accessibility h5 a,
body.high-contrast-accessibility h5 a b,
body.high-contrast-accessibility h5 abbr,
body.high-contrast-accessibility h5 b,
body.high-contrast-accessibility h5 center,
body.high-contrast-accessibility h5 em,
body.high-contrast-accessibility h5 i,
body.high-contrast-accessibility h5 span,
body.high-contrast-accessibility h5 strong,
body.high-contrast-accessibility h6,
body.high-contrast-accessibility h6 a,
body.high-contrast-accessibility h6 a b,
body.high-contrast-accessibility h6 abbr,
body.high-contrast-accessibility h6 b,
body.high-contrast-accessibility h6 center,
body.high-contrast-accessibility h6 em,
body.high-contrast-accessibility h6 i,
body.high-contrast-accessibility h6 span,
body.high-contrast-accessibility h6 strong,
body.high-contrast-accessibility header,
body.high-contrast-accessibility hgroup,
body.high-contrast-accessibility html,
body.high-contrast-accessibility i,
body.high-contrast-accessibility iframe,
body.high-contrast-accessibility input,
body.high-contrast-accessibility ins,
body.high-contrast-accessibility kbd,
body.high-contrast-accessibility label,
body.high-contrast-accessibility legend,
body.high-contrast-accessibility li,
body.high-contrast-accessibility listing,
body.high-contrast-accessibility main,
body.high-contrast-accessibility mark,
body.high-contrast-accessibility marquee,
body.high-contrast-accessibility menu,
body.high-contrast-accessibility meter,
body.high-contrast-accessibility multicol,
body.high-contrast-accessibility nav:not(#pojo-a11y-toolbar),
body.high-contrast-accessibility nobr,
body.high-contrast-accessibility object,
body.high-contrast-accessibility ol,
body.high-contrast-accessibility option,
body.high-contrast-accessibility output,
body.high-contrast-accessibility p,
body.high-contrast-accessibility plaintext,
body.high-contrast-accessibility pre,
body.high-contrast-accessibility progress,
body.high-contrast-accessibility q,
body.high-contrast-accessibility rb,
body.high-contrast-accessibility rp,
body.high-contrast-accessibility rt,
body.high-contrast-accessibility ruby,
body.high-contrast-accessibility s,
body.high-contrast-accessibility samp,
body.high-contrast-accessibility section,
body.high-contrast-accessibility small,
body.high-contrast-accessibility span,
body.high-contrast-accessibility strike,
body.high-contrast-accessibility strong,
body.high-contrast-accessibility sub,
body.high-contrast-accessibility summary,
body.high-contrast-accessibility sup,
body.high-contrast-accessibility svg,
body.high-contrast-accessibility table,
body.high-contrast-accessibility tbody,
body.high-contrast-accessibility td,
body.high-contrast-accessibility text,
body.high-contrast-accessibility textarea,
body.high-contrast-accessibility th,
body.high-contrast-accessibility thead,
body.high-contrast-accessibility time,
body.high-contrast-accessibility tr,
body.high-contrast-accessibility tt,
body.high-contrast-accessibility u,
body.high-contrast-accessibility ul,
body.high-contrast-accessibility var,
body.high-contrast-accessibility video,
body.high-contrast-accessibility xmp {
  background: var(--black-color) !important;
  background-color: var(--black-color) !important;
}

body.high-contrast-accessibility a,
body.high-contrast-accessibility article,
body.high-contrast-accessibility aside,
body.high-contrast-accessibility basefont,
body.high-contrast-accessibility bdi,
body.high-contrast-accessibility big,
body.high-contrast-accessibility blink,
body.high-contrast-accessibility blockquote,
body.high-contrast-accessibility body,
body.high-contrast-accessibility button,
body.high-contrast-accessibility canvas,
body.high-contrast-accessibility caption,
body.high-contrast-accessibility center,
body.high-contrast-accessibility code,
body.high-contrast-accessibility col,
body.high-contrast-accessibility colgroup,
body.high-contrast-accessibility command,
body.high-contrast-accessibility dd,
body.high-contrast-accessibility del,
body.high-contrast-accessibility details,
body.high-contrast-accessibility dir,
body.high-contrast-accessibility div,
body.high-contrast-accessibility dl,
body.high-contrast-accessibility dt,
body.high-contrast-accessibility embed,
body.high-contrast-accessibility fieldset,
body.high-contrast-accessibility figcaption,
body.high-contrast-accessibility figure,
body.high-contrast-accessibility font,
body.high-contrast-accessibility footer,
body.high-contrast-accessibility form,
body.high-contrast-accessibility header,
body.high-contrast-accessibility hgroup,
body.high-contrast-accessibility html,
body.high-contrast-accessibility iframe,
body.high-contrast-accessibility img,
body.high-contrast-accessibility input,
body.high-contrast-accessibility ins,
body.high-contrast-accessibility kbd,
body.high-contrast-accessibility label,
body.high-contrast-accessibility legend,
body.high-contrast-accessibility li,
body.high-contrast-accessibility listing,
body.high-contrast-accessibility main,
body.high-contrast-accessibility mark,
body.high-contrast-accessibility marquee,
body.high-contrast-accessibility menu,
body.high-contrast-accessibility meter,
body.high-contrast-accessibility multicol,
body.high-contrast-accessibility nav,
body.high-contrast-accessibility nobr,
body.high-contrast-accessibility object,
body.high-contrast-accessibility ol,
body.high-contrast-accessibility option,
body.high-contrast-accessibility output,
body.high-contrast-accessibility p,
body.high-contrast-accessibility plaintext,
body.high-contrast-accessibility pre,
body.high-contrast-accessibility progress,
body.high-contrast-accessibility q,
body.high-contrast-accessibility rb,
body.high-contrast-accessibility rp,
body.high-contrast-accessibility rt,
body.high-contrast-accessibility ruby,
body.high-contrast-accessibility s,
body.high-contrast-accessibility samp,
body.high-contrast-accessibility section,
body.high-contrast-accessibility select,
body.high-contrast-accessibility small,
body.high-contrast-accessibility span,
body.high-contrast-accessibility strike,
body.high-contrast-accessibility sub,
body.high-contrast-accessibility summary,
body.high-contrast-accessibility sup,
body.high-contrast-accessibility svg,
body.high-contrast-accessibility table,
body.high-contrast-accessibility tbody,
body.high-contrast-accessibility td,
body.high-contrast-accessibility text,
body.high-contrast-accessibility textarea,
body.high-contrast-accessibility th,
body.high-contrast-accessibility thead,
body.high-contrast-accessibility time,
body.high-contrast-accessibility tr,
body.high-contrast-accessibility tt,
body.high-contrast-accessibility ul,
body.high-contrast-accessibility var,
body.high-contrast-accessibility video,
body.high-contrast-accessibility xmp {
  color: var(--white-color)white !important;
}

body.high-contrast-accessibility abbr,
body.high-contrast-accessibility acronym,
body.high-contrast-accessibility b,
body.high-contrast-accessibility b span,
body.high-contrast-accessibility h1 b,
body.high-contrast-accessibility h1 strong,
body.high-contrast-accessibility h2 b,
body.high-contrast-accessibility h2 strong,
body.high-contrast-accessibility h3 b,
body.high-contrast-accessibility h3 strong,
body.high-contrast-accessibility h4 b,
body.high-contrast-accessibility h4 strong,
body.high-contrast-accessibility h5 b,
body.high-contrast-accessibility h5 strong,
body.high-contrast-accessibility h6 b,
body.high-contrast-accessibility h6 strong,
body.high-contrast-accessibility strong,
body.high-contrast-accessibility strong span {
  color: yellow !important;
}

body.high-contrast-accessibility address,
body.high-contrast-accessibility address span,
body.high-contrast-accessibility cite,
body.high-contrast-accessibility cite span,
body.high-contrast-accessibility dfn,
body.high-contrast-accessibility dfn span,
body.high-contrast-accessibility em,
body.high-contrast-accessibility em span,
body.high-contrast-accessibility h1 em,
body.high-contrast-accessibility h1 i,
body.high-contrast-accessibility h2 em,
body.high-contrast-accessibility h2 i,
body.high-contrast-accessibility h3 em,
body.high-contrast-accessibility h3 i,
body.high-contrast-accessibility h4 em,
body.high-contrast-accessibility h4 i,
body.high-contrast-accessibility h5 em,
body.high-contrast-accessibility h5 i,
body.high-contrast-accessibility h6 em,
body.high-contrast-accessibility h6 i,
body.high-contrast-accessibility i,
body.high-contrast-accessibility i span,
body.high-contrast-accessibility u,
body.high-contrast-accessibility u span {
  color: #FFFF80 !important;
}

body.high-contrast-accessibility dt {
  border-top: thin solid grey !important;
}

body.high-contrast-accessibility h1,
body.high-contrast-accessibility h1 a,
body.high-contrast-accessibility h1 a b,
body.high-contrast-accessibility h1 abbr,
body.high-contrast-accessibility h1 center,
body.high-contrast-accessibility h1 span,
body.high-contrast-accessibility h2,
body.high-contrast-accessibility h2 a,
body.high-contrast-accessibility h2 a b,
body.high-contrast-accessibility h2 abbr,
body.high-contrast-accessibility h2 center,
body.high-contrast-accessibility h2 span,
body.high-contrast-accessibility h3,
body.high-contrast-accessibility h3 a,
body.high-contrast-accessibility h3 a b,
body.high-contrast-accessibility h3 abbr,
body.high-contrast-accessibility h3 center,
body.high-contrast-accessibility h3 span,
body.high-contrast-accessibility h4,
body.high-contrast-accessibility h4 a,
body.high-contrast-accessibility h4 a b,
body.high-contrast-accessibility h4 abbr,
body.high-contrast-accessibility h4 center,
body.high-contrast-accessibility h4 span,
body.high-contrast-accessibility h5,
body.high-contrast-accessibility h5 a,
body.high-contrast-accessibility h5 a b,
body.high-contrast-accessibility h5 abbr,
body.high-contrast-accessibility h5 center,
body.high-contrast-accessibility h5 span,
body.high-contrast-accessibility h6,
body.high-contrast-accessibility h6 a,
body.high-contrast-accessibility h6 a b,
body.high-contrast-accessibility h6 abbr,
body.high-contrast-accessibility h6 center,
body.high-contrast-accessibility h6 span {
  color: #40C090 !important;
}

body.high-contrast-accessibility img {
  background: var(--label-disabled) !important;
  background-color: var(--label-disabled) !important;
}

body.high-contrast-accessibility abbr,
body.high-contrast-accessibility acronym {
  border-bottom: 1px dotted !important;
}

body.high-contrast-accessibility :focus {
  outline: thin dotted !important;
}

body.high-contrast-accessibility a.button,
body.high-contrast-accessibility a.button abbr,
body.high-contrast-accessibility a.button acronym,
body.high-contrast-accessibility a.button b,
body.high-contrast-accessibility a.button basefont,
body.high-contrast-accessibility a.button big,
body.high-contrast-accessibility a.button br,
body.high-contrast-accessibility a.button code,
body.high-contrast-accessibility a.button div,
body.high-contrast-accessibility a.button em,
body.high-contrast-accessibility a.button font,
body.high-contrast-accessibility a.button h1,
body.high-contrast-accessibility a.button h2,
body.high-contrast-accessibility a.button h3,
body.high-contrast-accessibility a.button h4,
body.high-contrast-accessibility a.button h5,
body.high-contrast-accessibility a.button h6,
body.high-contrast-accessibility a.button i,
body.high-contrast-accessibility a.button kbd,
body.high-contrast-accessibility a.button rb,
body.high-contrast-accessibility a.button rp,
body.high-contrast-accessibility a.button rt,
body.high-contrast-accessibility a.button ruby,
body.high-contrast-accessibility a.button samp,
body.high-contrast-accessibility a.button small,
body.high-contrast-accessibility a.button span,
body.high-contrast-accessibility a.button strong,
body.high-contrast-accessibility a.button tt,
body.high-contrast-accessibility a.button u,
body.high-contrast-accessibility a.button var,
body.high-contrast-accessibility a:link,
body.high-contrast-accessibility a:link abbr,
body.high-contrast-accessibility a:link acronym,
body.high-contrast-accessibility a:link b,
body.high-contrast-accessibility a:link basefont,
body.high-contrast-accessibility a:link big,
body.high-contrast-accessibility a:link br,
body.high-contrast-accessibility a:link code,
body.high-contrast-accessibility a:link div,
body.high-contrast-accessibility a:link em,
body.high-contrast-accessibility a:link font,
body.high-contrast-accessibility a:link h1,
body.high-contrast-accessibility a:link h2,
body.high-contrast-accessibility a:link h3,
body.high-contrast-accessibility a:link h4,
body.high-contrast-accessibility a:link h5,
body.high-contrast-accessibility a:link h6,
body.high-contrast-accessibility a:link i,
body.high-contrast-accessibility a:link kbd,
body.high-contrast-accessibility a:link rb,
body.high-contrast-accessibility a:link rp,
body.high-contrast-accessibility a:link rt,
body.high-contrast-accessibility a:link ruby,
body.high-contrast-accessibility a:link samp,
body.high-contrast-accessibility a:link small,
body.high-contrast-accessibility a:link span,
body.high-contrast-accessibility a:link strong,
body.high-contrast-accessibility a:link tt,
body.high-contrast-accessibility a:link u,
body.high-contrast-accessibility a:link var,
body.high-contrast-accessibility div#secondaryNav div#documentNavigation ul.navigationTabs li.tabItem {
  color: #0080FF !important;
}

body.high-contrast-accessibility button,
body.high-contrast-accessibility input,
body.high-contrast-accessibility textarea,
body.high-contrast-accessibility select,
body.high-contrast-accessibility table,
body.high-contrast-accessibility td,
body.high-contrast-accessibility th,
body.high-contrast-accessibility tr,
body.high-contrast-accessibility tt {
  border: 1px solid var(--white-color) !important;
}

body.high-contrast-accessibility button {
  background: #600040 !important;
  background-color: #600040 !important;
}

body.high-contrast-accessibility select {
  -webkit-appearance: listbox !important;
  background: #600060 !important;
  background-color: #600060 !important;
}

body.high-contrast-accessibility a:visited,
body.high-contrast-accessibility a:visited abbr,
body.high-contrast-accessibility a:visited acronym,
body.high-contrast-accessibility a:visited b,
body.high-contrast-accessibility a:visited basefont,
body.high-contrast-accessibility a:visited big,
body.high-contrast-accessibility a:visited br,
body.high-contrast-accessibility a:visited code,
body.high-contrast-accessibility a:visited div,
body.high-contrast-accessibility a:visited em,
body.high-contrast-accessibility a:visited font,
body.high-contrast-accessibility a:visited h1,
body.high-contrast-accessibility a:visited h2,
body.high-contrast-accessibility a:visited h3,
body.high-contrast-accessibility a:visited h4,
body.high-contrast-accessibility a:visited h5,
body.high-contrast-accessibility a:visited h6,
body.high-contrast-accessibility a:visited i,
body.high-contrast-accessibility a:visited kbd,
body.high-contrast-accessibility a:visited rb,
body.high-contrast-accessibility a:visited rp,
body.high-contrast-accessibility a:visited rt,
body.high-contrast-accessibility a:visited ruby,
body.high-contrast-accessibility a:visited samp,
body.high-contrast-accessibility a:visited small,
body.high-contrast-accessibility a:visited span,
body.high-contrast-accessibility a:visited strong,
body.high-contrast-accessibility a:visited tt,
body.high-contrast-accessibility a:visited u,
body.high-contrast-accessibility a:visited var,
body.high-contrast-accessibility div#secondaryNav div#documentNavigation ul.navigationTabs li.tabItem.active {
  color: #00FFFF !important;
}

body.high-contrast-accessibility ::selection,
body.high-contrast-accessibility ::-moz-selection {
  background: #4080c0 !important;
  background-color: #4080c0 !important;
}

body.high-contrast-accessibility a.button:hover,
body.high-contrast-accessibility a.button:hover abbr,
body.high-contrast-accessibility a.button:hover acronym,
body.high-contrast-accessibility a.button:hover b,
body.high-contrast-accessibility a.button:hover basefont,
body.high-contrast-accessibility a.button:hover big,
body.high-contrast-accessibility a.button:hover br,
body.high-contrast-accessibility a.button:hover code,
body.high-contrast-accessibility a.button:hover div,
body.high-contrast-accessibility a.button:hover em,
body.high-contrast-accessibility a.button:hover font,
body.high-contrast-accessibility a.button:hover h1,
body.high-contrast-accessibility a.button:hover h2,
body.high-contrast-accessibility a.button:hover h3,
body.high-contrast-accessibility a.button:hover h4,
body.high-contrast-accessibility a.button:hover h5,
body.high-contrast-accessibility a.button:hover h6,
body.high-contrast-accessibility a.button:hover i,
body.high-contrast-accessibility a.button:hover kbd,
body.high-contrast-accessibility a.button:hover rb,
body.high-contrast-accessibility a.button:hover rp,
body.high-contrast-accessibility a.button:hover rt,
body.high-contrast-accessibility a.button:hover ruby,
body.high-contrast-accessibility a.button:hover samp,
body.high-contrast-accessibility a.button:hover small,
body.high-contrast-accessibility a.button:hover span,
body.high-contrast-accessibility a.button:hover strong,
body.high-contrast-accessibility a.button:hover tt,
body.high-contrast-accessibility a.button:hover u,
body.high-contrast-accessibility a.button:hover var,
body.high-contrast-accessibility a:link:hover,
body.high-contrast-accessibility a:link:hover abbr,
body.high-contrast-accessibility a:link:hover acronym,
body.high-contrast-accessibility a:link:hover b,
body.high-contrast-accessibility a:link:hover basefont,
body.high-contrast-accessibility a:link:hover big,
body.high-contrast-accessibility a:link:hover br,
body.high-contrast-accessibility a:link:hover code,
body.high-contrast-accessibility a:link:hover div,
body.high-contrast-accessibility a:link:hover em,
body.high-contrast-accessibility a:link:hover font,
body.high-contrast-accessibility a:link:hover h1,
body.high-contrast-accessibility a:link:hover h2,
body.high-contrast-accessibility a:link:hover h3,
body.high-contrast-accessibility a:link:hover h4,
body.high-contrast-accessibility a:link:hover h5,
body.high-contrast-accessibility a:link:hover h6,
body.high-contrast-accessibility a:link:hover i,
body.high-contrast-accessibility a:link:hover kbd,
body.high-contrast-accessibility a:link:hover rb,
body.high-contrast-accessibility a:link:hover rp,
body.high-contrast-accessibility a:link:hover rt,
body.high-contrast-accessibility a:link:hover ruby,
body.high-contrast-accessibility a:link:hover samp,
body.high-contrast-accessibility a:link:hover small,
body.high-contrast-accessibility a:link:hover span,
body.high-contrast-accessibility a:link:hover strong,
body.high-contrast-accessibility a:link:hover tt,
body.high-contrast-accessibility a:link:hover u,
body.high-contrast-accessibility a:link:hover var,
body.high-contrast-accessibility a:visited:hover,
body.high-contrast-accessibility a:visited:hover abbr,
body.high-contrast-accessibility a:visited:hover acronym,
body.high-contrast-accessibility a:visited:hover b,
body.high-contrast-accessibility a:visited:hover basefont,
body.high-contrast-accessibility a:visited:hover big,
body.high-contrast-accessibility a:visited:hover br,
body.high-contrast-accessibility a:visited:hover code,
body.high-contrast-accessibility a:visited:hover div,
body.high-contrast-accessibility a:visited:hover em,
body.high-contrast-accessibility a:visited:hover font,
body.high-contrast-accessibility a:visited:hover h1,
body.high-contrast-accessibility a:visited:hover h2,
body.high-contrast-accessibility a:visited:hover h3,
body.high-contrast-accessibility a:visited:hover h4,
body.high-contrast-accessibility a:visited:hover h5,
body.high-contrast-accessibility a:visited:hover h6,
body.high-contrast-accessibility a:visited:hover i,
body.high-contrast-accessibility a:visited:hover kbd,
body.high-contrast-accessibility a:visited:hover rb,
body.high-contrast-accessibility a:visited:hover rp,
body.high-contrast-accessibility a:visited:hover rt,
body.high-contrast-accessibility a:visited:hover ruby,
body.high-contrast-accessibility a:visited:hover samp,
body.high-contrast-accessibility a:visited:hover small,
body.high-contrast-accessibility a:visited:hover span,
body.high-contrast-accessibility a:visited:hover strong,
body.high-contrast-accessibility a:visited:hover tt,
body.high-contrast-accessibility a:visited:hover u,
body.high-contrast-accessibility a:visited:hover var {
  background: #400000 !important;
  background-color: #400000 !important;
}

body.high-contrast-accessibility body>input#site+div#wrapper span.mk,
body.high-contrast-accessibility body>input#site+div#wrapper span.mk b,
body.high-contrast-accessibility input[type=reset] {
  background: #400060 !important;
  background-color: #400060 !important;
}

body.high-contrast-accessibility div[role="button"],
body.high-contrast-accessibility input[type=button],
body.high-contrast-accessibility input[type=submit] {
  background: #600040 !important;
  background-color: #600040 !important;
}

body.high-contrast-accessibility input[type=search] {
  -webkit-appearance: textfield !important;
}

body.high-contrast-accessibility html button[disabled],
body.high-contrast-accessibility html input[disabled],
body.high-contrast-accessibility html select[disabled],
body.high-contrast-accessibility html textarea[disabled] {
  background: #404040 !important;
  background-color: #404040 !important;
}

body.high-contrast-accessibility .menu li a span.label {
  text-transform: none !important;
}

body.high-contrast-accessibility .menu li a span.label,
body.high-contrast-accessibility div.jwplayer span.jwcontrolbar,
body.high-contrast-accessibility div.jwplayer span.jwcontrols {
  display: inline !important;
}

body.high-contrast-accessibility a:link.new,
body.high-contrast-accessibility a:link.new i,
body.high-contrast-accessibility a:link.new b,
body.high-contrast-accessibility span.Apple-style-span {
  color: #FFFF40 !important;
}

body.high-contrast-accessibility body.mediawiki img.tex {
  background: var(--white-color) !important;
  background-color: var(--white-color) !important;
  border: var(--white-color) solid 0.1875em !important;
}

body.high-contrast-accessibility text>tspan:first-letter,
body.high-contrast-accessibility text>tspan:first-line {
  background: inherit !important;
  background-color: inherit !important;
  color: inherit !important;
}

body.high-contrast-accessibility div.sbtc div.sbsb_a li.sbsb_d div,
body.high-contrast-accessibility table.gssb_c tr.gssb_i a,
body.high-contrast-accessibility table.gssb_c tr.gssb_i b,
body.high-contrast-accessibility table.gssb_c tr.gssb_i span,
body.high-contrast-accessibility table.gssb_c tr.gssb_i td {
  background: #003050 !important;
  background-color: #003050 !important;
}

body.high-contrast-accessibility img[width="18"][height="18"] {
  height: 18px !important;
  width: 18px !important;
}

body.high-contrast-accessibility a>span.iconHelp:empty:after {
  content: "Help" !important;
}

body.high-contrast-accessibility div#gmap,
body.high-contrast-accessibility div#gmap * {
  background: initial !important;
}

/** END HIGH CONTRAST **/