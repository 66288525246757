:root {
    --primary-color: #0066CC;
    --light-blue-color: #0073e6;
    --dark-blue-color: #17324D;
    --blue-secondary-color: #004080;
    --blue-tertiary-color: #003569;
    --gray-color: #5C6F82;
    --gray-paragraph-color: #455B71;
    --shadow-box: rgba(0, 43, 85, 0.1);
    --drop-shadow: rgb(0 0 0 / 0.1);
    --divider-color: #D8D8D8;
    --alert-color: #D9364F;
    --green-color: #008758;
    --black-color: #000000;
    --disabled-color: #e6e9f2;
    --label-disabled: #808080;
    --title-text: #1C2024;
    --light-background: #F6F9FC;
    --white-color: #FFFFFF;
    --panel-color: #E7F1FF;
}